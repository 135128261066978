import React from "react"
import {Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
}

const BlockContent = require("@sanity/block-content-to-react")

export const query = graphql`
  query($slug: String) {
    event: sanityClubevent(slug: { current: { eq: $slug } }) {
      title
      mainImage {
        asset {
          fluid {
            src
            base64
            srcSetWebp
            ...GatsbySanityImageFluid
          }
        }
      }
      startDate(formatString: "MMM DD YYYY")
      endDate(formatString: "MMM DD YYYY")
      club {
        title
        description
      }
      _rawBody
    }
  }
`

export default function ({ data: { event } }) {
  return (
    <Layout>
      <section className="m-0 p-0 section-for-small-devices">
        <section className="lg:flex items-center bg-gray-200 mt-8 py-16">
          <div className="lg:w-1/2 lg:pr-8">
            <h1 className="section-head">{event.title}</h1>
            <div className="">
              <p className=" lg:mt-16 mt-8 text-xs font-form uppercase font-medium ">
                {event.startDate} – {event.endDate}
              </p>
              <h4 className=" capitalize lg:mt-4 mt-2">
                <span className="font-medium  text-lg">{event.club.title}</span>{" "}
                | <span className="text-sm">Organizer</span>
              </h4>
              {event.title.description !== null ? (
                <p className="lg:mt-1 text-base"> {event.club.description}</p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="lg:w-1/2 lg:pl-8 mt-8 lg:mt-0">
            <Img
              fluid={event.mainImage.asset.fluid}
              alt={event.title}
              draggable={false}
            />
          </div>
        </section>
        <section className="sanity-block" style={{ marginTop: "0" }}>
          <BlockContent blocks={event._rawBody} serializers={serializers} />
        </section>
      </section>
     
      <Link
        className="mt-16 inline-block  hover:underline mx-4 sm:mx-8"
        to="/events"
      >
        &larr; View other Events
      </Link>

    </Layout>
              
  )
}
